import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { pxToRem } from '@rjp/common/utils';
import {
  setShowContactAdvisorModal,
  setActiveContactAdvisorModalConfig,
} from '@rjp/main/src/features/contactAdvisor/contactAdvisorSlice';
import { AppDispatch, RootState } from '@rjp/main/src/app';
import { IUserInfoState } from '@rjp/main/src/features/userInfo/userInfoSlice';
// import { CurFormattedMessage as FormattedMessage, ICurFormattedMessage, Modal, CheckBox } from '@rjp/common/component';
import { TalkToAnAdvisor, AssignAnAdvisor } from './assignAnAdvisor';
import ClientAdvisorProduct from './ClientAdvisorProduct';
import { contactAdvisor } from '@rjp/main/src/api';
import { trackingFunc, RJP_LINK } from '@/utils/tracking';

interface ProductsProps {
  clientInfo?: IUserInfoState;
  memberShowQuotes: boolean;
}

// const prefix = 'WorkSurface.Home.Products.';

// const CurFormattedMessage:FC<ICurFormattedMessage> = ({ id, ...props }) => (
//   <FormattedMessage
//     {...props}
//     id={`${prefix}${id}`}
//   />
// );

// const TalkAdvisorModal = styled(Modal)`
//   .ant-modal-body {
//     padding-top: ${pxToRem(70)};
//     padding-bottom: 0;
//   }
//   .ant-modal-footer {
//     padding-top: ${pxToRem(47)};
//     padding-bottom: ${pxToRem(38)};
//   }
// `;

// const TalkAdvisorModalDesc = styled(CurFormattedMessage)`
//   width: ${pxToRem(525)};
//   font-size: ${pxToRem(16)};
//   line-height: ${pxToRem(21)};
//   margin-left: ${pxToRem(44)};
//   color: ${props => props.theme.color.grey_dark};
//   margin-top:  ${pxToRem(5)};
// `;

// const TalkAdvisorModalTitle = styled(CurFormattedMessage)`
//   height: ${pxToRem(50)};
//   line-height: ${pxToRem(50)};
//   font-size: ${pxToRem(24)};
//   text-align: center;
//   font-weight: 500;
//   color: ${props => props.theme.color.sunlife_blue_medium};
// `;

// const TAMCheckboxWrapper = styled.div`
//   width: ${pxToRem(520)};
//   margin-left: ${pxToRem(44)};
//   margin-top: ${pxToRem(17)};
//   padding-top: ${pxToRem(18)};
//   padding-bottom: ${pxToRem(18)};
//   padding-left: ${pxToRem(20)};
//   display: flex;
//   background: ${props => props.theme.color.cool_blue_light_20};
// `;

// const TAMCheckboxTip = styled(CurFormattedMessage)`
//   margin-left: ${pxToRem(13)};
// `;

const ProductsWrapper = styled.div`
  /* padding-bottom: ${pxToRem(25)}; */
`;

const Products: FC<ProductsProps> = ({ clientInfo, memberShowQuotes }) => {
  // const [showContactAdvisorModal, setShowContactAdvisorModal] = useState<boolean>(false);
  // const [isConsentAccessAccount, setIsConsentAccessAccount] = useState<boolean>(true);
  // const [isRequested, setIsRequested] = useState<boolean>(false);
  const [currentState, setCurrentState] = useState<string>('NoProductsYet');
  const [currentErrorCode, setCurrentErrorCode] = useState<number>();
  const dispatch = useDispatch<AppDispatch>();
  const { isRequestedAdvisor } = useSelector(
    (state: RootState) => state.contactAdvisor,
    shallowEqual
  );

  const currentRole = useSelector((state: RootState) => state.userInfo.role);
  const { id = '', consent_expiry_date } = useSelector(
    (state: RootState) => state.userInfo,
    shallowEqual
  );

  const requestAdvisor = async () => {
    if (consent_expiry_date && dayjs(consent_expiry_date).isValid()) {
      try {
        // use with consent_expiry_date should call contactAdvisor API again and decide the state.
        // error code should be returned for decision
        await contactAdvisor({ id, is_request_consent: false });
      } catch (error: any) {
        setCurrentErrorCode(error);
        if (error === 10017) {
          // 10017 means no advisor pick up the request yet, still pending
          dispatch(setActiveContactAdvisorModalConfig('pendingAssignmnet'));
        } else if (error === 10018) {
          // 10018 means advisor has picked up the request.
          dispatch(setActiveContactAdvisorModalConfig('extend'));
        }
      }
    } else {
      // user without consent_expiry_date should request advisor
      dispatch(setActiveContactAdvisorModalConfig('requestAdvisor'));
    }
    dispatch(setShowContactAdvisorModal(true));
  };

  useEffect(() => {
    const callContactAdvisor = async () => {
      if (consent_expiry_date && dayjs(consent_expiry_date).isValid()) {
        try {
          // use with consent_expiry_date should call contactAdvisor API again and decide the state.
          // error code should be returned for decision
          await contactAdvisor({ id, is_request_consent: false });
        } catch (error: any) {
          setCurrentErrorCode(error);
          if (error === 10017) {
            // 10017 means no advisor pick up the request yet, still pending
            dispatch(setActiveContactAdvisorModalConfig('pendingAssignmnet'));
            setCurrentState('MemberWaitAdvisorTalkToAnAdvisor');
          } else if (error === 10018) {
            // 10018 means advisor has picked up the request.
            dispatch(setActiveContactAdvisorModalConfig('extend'));
          }
        }
      } else {
        // user without consent_expiry_date should request advisor
        dispatch(setActiveContactAdvisorModalConfig('requestAdvisor'));
      }
    };
    callContactAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  useEffect(() => {
    const validDate = !!(
      consent_expiry_date && dayjs(consent_expiry_date).isValid()
    );
    if (!consent_expiry_date) {
      setCurrentState('NoProductsYet');
    } else if (validDate && currentErrorCode === 10017 && !memberShowQuotes) {
      setCurrentState('MemberWaitAdvisorTalkToAnAdvisor');
    } else if (validDate && currentErrorCode === 10018 && memberShowQuotes) {
      setCurrentState('memberShowQuotes');
    } else if (validDate && currentErrorCode === 10018 && !memberShowQuotes) {
      setCurrentState('MemberTalkToAnAdvisor');
    }
  }, [
    memberShowQuotes,
    currentErrorCode,
    consent_expiry_date,
    isRequestedAdvisor,
  ]);

  return (
    <ProductsWrapper>
      {/*<ContactAdvisorModal visible={showContactAdvisorModal} onClose={requestAdvisor} /> */}
      {/* <TalkAdvisorModal
        visible={showTalkAdvisorModal}
        width={718}
        footerConfig={
          [
            { width: 170, type: 'secondary', message: `${prefix}Cancel`, onClick: () => setShowTalkAdvisorModal(false) },
            { width: 170, type: 'primary', message: `${prefix}RequestAdvisor`, style: { marginLeft: pxToRem(20) }, onClick: requestAdvisor }
          ]
        }
        onClose={() => setShowTalkAdvisorModal(false)}
      >
        <TalkAdvisorModalTitle id="LetOurAdvisors" />
        <TalkAdvisorModalDesc id="YourRetirementInformation" />
        <TAMCheckboxWrapper>
          <CheckBox
            defaultChecked={isConsentAccessAccount}
            type="primary-blue"
            onChange={(name, value) => setIsConsentAccessAccount(value)}
          />
          <TAMCheckboxTip id="IGiveConsent" />
        </TAMCheckboxWrapper>
      </TalkAdvisorModal> */}
      {currentRole !== 'member' && (
        <ClientAdvisorProduct clientInfo={clientInfo} />
      )}
      {/*{*/}
      {/*  currentRole === 'member' && memberShowQuotes && showProductsQuotes*/}
      {/*    ? <ClientAdvisorProduct clientInfo={clientInfo} />*/}
      {/*    :*/}
      {/*    currentRole === 'member' && isRequestedAdvisor*/}
      {/*      ? <AssignAnAdvisor />*/}
      {/*      : <TalkToAnAdvisor currentState={currentState} onTalkToAdvisor={requestAdvisor} />*/}
      {/*}*/}
      {currentRole === 'member' &&
        (currentState === 'memberShowQuotes' ? (
          <ClientAdvisorProduct clientInfo={clientInfo} />
        ) : isRequestedAdvisor ? (
          <AssignAnAdvisor />
        ) : (
          <TalkToAnAdvisor
            currentState={currentState}
            onTalkToAdvisor={() => {requestAdvisor(); trackingFunc(RJP_LINK, 'other', 'talk to an advisor')}}
          />
        ))}
    </ProductsWrapper>
  );
};

export default Products;
