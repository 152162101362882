import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router';

import {
  filterObj,
  isEmptyField,
  pxToRem,
  isEmptyFields,
  currencyFormat,
  percentageFormat,
  maxTotalValue,
} from '@rjp/common/utils';
import {
  IFormField,
  ShowValue,
} from '@rjp/common/template/retirementDetailsItems';
import { RetirementDetailsItems } from '@rjp/common/template';
import { ButtonsFooter } from '@rjp/common/component/modal/FooterLayout';
import { RootState, AppDispatch } from '@rjp/main/src/app';
import { updateRetirementProfile } from '@rjp/main/src/features/rcData/rcDataSlice';
import {
  createRetirementProfile,
  updateRetirementProfile as updateRetirementProfileAPI,
} from '@rjp/main/src/api';
import {
  IUserInfoState,
  updateUserInfo,
} from '@rjp/main/src/features/userInfo/userInfoSlice';
import { editSessionStatus } from '@rjp/main/src/features/advisorEdit/advisorEditSlice';
import ErrorContext from '../homeContext';
import { editRequest, editRequestVerify } from '../../../../api';
import {
  addCards,
  addConfigs,
  formConfig,
  fieldMap,
  futurePayments,
  getRetirementMonthlyIncome,
  getRetirementMonthlyStyle,
  getRetirementMonthlyMinMax,
  maxMinErrorMsg,
  needStepFields,
  otherInvestments,
  sliderFieldsName,
  sliderFieldsMap,
  needCurrentHandleErrorFields,
} from './config';

import {
  ExpireInMinutes,
  LastMinute,
  RequestPermission,
  SessionExpired,
  VerificationCode,
} from './validationModal';
import { RetirementGapModal } from './modal';
// import PurchasedSunLifeProducts from "@/pages/workSurface/home/details/PurchasedSunLifeProducts";

interface DetailsProps {
  modalVisible: Record<string, boolean>;
  setModalVisible: (name: string, visible: boolean) => void;
  clientInfo?: IUserInfoState;
}

const DetailsContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DetailsContent = styled.div`
  width: ${pxToRem(670)};
  font-size: ${pxToRem(30)};
  flex: 1;
  color: #333;
  font-weight: bold;
  padding: ${pxToRem(14)} 0 ${pxToRem(90)} 0;
  box-sizing: border-box;
  overflow: auto;
`;

const DetailsButtonsFooter = styled.div`
  width: ${pxToRem(670)};
  height: ${pxToRem(80)};
  box-shadow: 0px ${pxToRem(-2)} ${pxToRem(2)}
    ${(props) => props.theme.color.extra_dark_10};
  background: ${(props) => props.theme.color.white};
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 10;

  .ant-btn:nth-child(1) {
    margin-left: ${pxToRem(42)};
  }

  .ant-btn {
    margin-left: ${pxToRem(20)};
  }
`;

const DetailsNote = styled.div`
  width: ${pxToRem(583)};
  margin: 0 auto;
  margin-bottom: ${pxToRem(13)};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  font-weight: 400;
`;

const createIsEditObj = () => ({
  personalInformation: false,
  incomeAndCashSavings: false,
  retirementTarget: false,
  mPF: false,
  otherInvestments: false,
  futurePayments: false,
});

const initialCurrentData = {
  age: 0,
  retirement_monthly_income: 3000,
  retirement_monthly_income_style: 'Modest',
  post_retirement_investment_mix: 'Conservative',
  mpf_investment_mix: 'Conservative',
  no_of_children: 0,
  monthly_income: 0,
  total_cash_savings: 0,
  monthly_cash_savings: 0,
  total_mpf_savings: 0,
  monthly_mpf_savings: 0,
  future_payments: [],
  other_investment: [],
};

const Details: FC<DetailsProps> = ({
  modalVisible,
  setModalVisible,
  clientInfo,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const retirementProfile = useSelector(
    (state: RootState) => state.RCData.retirementProfile
  );
  const location = useLocation();
  const history = useHistory();
  const role =
    useSelector((state: RootState) => state.userInfo.role) ?? 'member';
  const { setShowError, setErrorCode } = useContext(ErrorContext);

  const [currentData, setCurrentData] = useState<Record<string, any>>({
    ...initialCurrentData,
    ...retirementProfile,
  });

  const [controlVModal, setControlVModal] = useState<Record<string, boolean>>({
    expireInMinutes: false,
    lastMinute: false,
    requestPermission: false,
    sessionExpired: false,
    verificationCode: false,
  });

  const advisorCanEdit = useRef<boolean>(false);

  const [resendCountdown, setResendCountdown] = useState<number>(0);

  const [expiredCountdown, setExpiredResendCountdown] = useState<number>(0);

  const [codeError, setCodeError] = useState<boolean>(false);

  const sendedVCode = useRef<boolean>(false);

  const isShowLastMinute = useRef<boolean>(false);

  const isShowExpireInMinutes = useRef<boolean>(false);

  let resendInterval:NodeJS.Timeout;

  let expiredTimeResendInterval:NodeJS.Timeout;

  const activeName = useRef<string>('');

  const [clientId, setClientId] = useState<string>('');

  const advisorEdit = useSelector((state: RootState) => state.advisorEdit);

  const [isEdit, setIsEdit] = useState<Record<string, boolean>>(
    createIsEditObj
  );

  const [errorState, setErrorState] = useState<Record<string, boolean>>({
    age: false,
    gender: false,
    marital_status: false,
    smoking_status: false,
    monthly_income: false,
    retirement_age: false,
  });

  const [maxMinErrorState, setMaxMinErrorState] = useState<
    Record<string, boolean>
  >({
    age: false,
    monthly_income: false,
    retirement_age: false,
  });

  const [saveChanged, setSaveChanged] = useState(false);

  const [minMaxStep, setMinMaxStep] = useState<Record<string, any>>({
    age: { min: 18, max: 69 },
    retirement_age: { min: 40, max: 70 },
    age_of_receipt: {
      min: 40,
      max: 120,
      errorMsg: 'ErrorMsg.PleaseInputTheAge',
    },
    monthly_income: { min: 0, max: 300000 },
    total_cash_savings: { min: 0, max: 50000000 },
    monthly_cash_savings: {
      min: 0,
      max: 100,
      suffix: currencyFormat(0),
      tooltipValue: '',
      maxTooltipValue: 1,
    },
    retirement_monthly_income: { min: 3000, max: 6000 },
    total_mpf_savings: { min: 0, max: 50000000 },
    monthly_mpf_savings: { min: 0, max: 300000 },
  });

  const isEmpty = useMemo(() => !isEmptyField(isEdit, 'boolean'), [isEdit]);

  const [currentConfigs, setCurrentConfigs] = useState<Record<string, any>>({
    otherInvestments,
    futurePayments,
  });
  const currentConfig: Record<string, any>[] = useMemo(
    () => [
      ...formConfig,
      {
        name: 'otherInvestments',
        config: currentConfigs.otherInvestments,
        type: 'add',
        addMsg: 'Product.AddInvestmentAssets',
        subTitleConfig: {
          title: 'PortfolioTotal',
          name: 'portfolio_total',
        },
        titleConfig: {
          title: 'Product.OtherInvestments',
          name: 'otherInvestments',
        },
      },
      {
        name: 'futurePayments',
        config: currentConfigs.futurePayments,
        type: 'add',
        addMsg: 'Product.AddFunds',
        subTitleConfig: {
          title: 'FundsTotal',
          name: 'funds_total',
        },
        titleConfig: {
          title: 'Product.OtherFunds',
          name: 'futurePayments',
        },
      },
    ],
    [currentConfigs]
  );
  const defaultConfigs: Record<string, any> = {
    otherInvestments: {
      investment_description: '',
      amount: 0,
      other_investment_style: 1,
    },
    futurePayments: {
      payment_description: '',
      amount: 0,
      is_after_retirement: false,
      age_of_receipt: '',
    },
  };

  const onBlur = (name: string, value: number | string, cardName?: string) => {
    if (
      name === 'age' &&
      cardName !== 'otherInvestments' &&
      cardName !== 'futurePayments'
    ) {
      setModalVisible(name, value > 69);
    }
  };

  const switchVModal = (key: string) => {
    setControlVModal((data) => {
      const curData = { ...data };
      Object.keys(curData).forEach((k) => {
        curData[k] = k === key ? !curData[key] : false;
      });
      return curData;
    });
  };

  const changeConfig = useCallback(
    (name: string) => {
      const curConfig: Record<string, any>[] = [];
      (currentData[fieldMap[name]] || []).forEach(
        (item: Record<string, any>, index: number) => {
          const currentAddConfig = addConfigs[name].map((c: IFormField) => ({
            ...c,
            index,
          }));
          curConfig.push(...currentAddConfig);
        }
      );
      const length = curConfig.length / 2 - 1;
      if (name === 'otherInvestments') {
        handleErrorState('investment_description{index}', false);
      } else {
        handleMaxMinErrorState(`age_of_receipt$${length}`, false);
        handleErrorState(`payment_description${length}`, false);
      }
      setCurrentConfigs((config) => ({
        ...config,
        [name]: curConfig,
      }));
    },
    [currentData]
  );

  useEffect(() => {
    const deepRetirementProfile = JSON.parse(
      JSON.stringify(retirementProfile || {})
    );
    const monthlyCashSavings = deepRetirementProfile?.monthly_cash_savings || 0;
    if (retirementProfile?.monthly_income) {
      const monthlyIncome = retirementProfile.monthly_income;
      const savings = retirementProfile?.monthly_mpf_savings || 0;
      const max = maxTotalValue(monthlyIncome, savings);
      const { min: rMin, max: rMax } = getRetirementMonthlyMinMax(
        monthlyIncome
      );
      const curTooltipValue = percentageFormat(
        deepRetirementProfile?.monthly_cash_savings
      );
      setMinMaxStep((state) => ({
        ...state,
        retirement_monthly_income: {
          ...state.retirement_monthly_income,
          min: rMin,
          max: rMax,
        },
        monthly_cash_savings: {
          ...state.monthly_cash_savings,
          max,
          suffix: currencyFormat(
            retirementProfile?.monthly_income * monthlyCashSavings
          ),
          tooltipValue: curTooltipValue,
        },
      }));
    }
    setCurrentData((prevState) => ({
      ...prevState,
      ...deepRetirementProfile,
      monthly_cash_savings: monthlyCashSavings,
    }));
  }, [retirementProfile]);

  useEffect(() => {
    const { age, retirement_age } = currentData;
    if (age) {
      const { max } = minMaxStep.age;
      const retirementAgeMin = age > max ? max + 1 : age > 39 ? +(age + 1) : 40;
      let ageOfReceiptMin = age > 39 ? +(age + 2) : 41;
      if (retirement_age) {
        ageOfReceiptMin = retirement_age >= 71 ? 71 : +(retirement_age + 1);
      }
      setMinMaxStep((state) => ({
        ...state,
        retirement_age: {
          ...state.retirement_age,
          min: retirementAgeMin,
        },
        age_of_receipt: { ...state.age_of_receipt, min: ageOfReceiptMin },
      }));
    }
  }, [currentData, minMaxStep.age]);

  const handleErrorState = (name: string, error: boolean) => {
    setErrorState((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const handleMaxMinErrorState = (name: string, error: boolean) => {
    setMaxMinErrorState((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const allErrorState = useMemo(
    () => ({
      ...maxMinErrorState,
      ...errorState,
    }),
    [errorState, maxMinErrorState]
  );

  const activeTopError = useMemo(() => {
    let activeConfig = '';
    let stop = false;
    for (let i = 0; i < currentConfig.length; i += 1) {
      const { config } = currentConfig[i];
      for (let configIndex = 0; configIndex < config.length; configIndex += 1) {
        const configItem = config[configIndex];
        if (allErrorState[configItem.name]) {
          stop = true;
          activeConfig = configItem.name;
        }
        if (allErrorState[`${configItem?.inputName}${configItem.index}`]) {
          stop = true;
          activeConfig = `${configItem?.inputName}${configItem.index}`;
        }
        if (stop) break;
      }
      if (stop) break;
    }
    return activeConfig;
  }, [allErrorState, currentConfig]);

  const openEditInError = useCallback(() => {
    const configNames: string[] = [];
    currentConfig.forEach((config) => {
      config.config.forEach((item: any) => {
        if (
          allErrorState[item.name] &&
          configNames.indexOf(config.name) === -1
        ) {
          configNames.push(config.name);
        }
      });
    });

    if (configNames.length) {
      setIsEdit((data) => {
        const curData = { ...data };
        configNames.forEach((name) => {
          curData[name] = true;
        });
        return curData;
      });
    }
  }, [currentConfig, allErrorState]);

  useEffect(() => {
    if (activeTopError && saveChanged) {
      openEditInError();
    }
  }, [activeTopError, saveChanged, openEditInError]);

  const updateMonthlyCashIncome = useCallback(
    (max: number, suffix: string, tooltipValue: number) => {
      setMinMaxStep((state) => ({
        ...state,
        monthly_cash_savings: {
          ...state.monthly_cash_savings,
          max,
          suffix,
          tooltipValue: `${tooltipValue}%`,
        },
      }));
    },
    []
  );

  const handleAgeOfReceiptError = (ageOfReceiptMin: number) => {
    if (currentData.future_payments.length) {
      currentData.future_payments.forEach(
        (
          item: {
            payment_description: string;
            is_after_retirement: boolean;
            age_of_receipt: number | '';
          },
          index: number
        ) => {
          if (item.age_of_receipt) {
            const ageOfReceiptError =
              item.is_after_retirement && item.age_of_receipt < ageOfReceiptMin;
            handleMaxMinErrorState(`age_of_receipt${index}`, ageOfReceiptError);
            setErrorState((state) => ({
              ...state,
              [`age_of_receipt${index}`]: ageOfReceiptError,
            }));
          }
        }
      );
    }
  };

  const updateValue = (
    value: any,
    name: string,
    cardName?: string,
    index?: number,
    type?: string
  ) => {
    const isAddCard = addCards.includes(cardName || '');
    if (isAddCard) {
      const currentCardName = cardName || 'otherInvestments';
      let curData = currentData[fieldMap[currentCardName]];
      if (name === 'age_of_receipt') {
        const { min, max } = minMaxStep.age_of_receipt;
        setMinMaxStep((state) => ({
          ...state,
          age_of_receipt: {
            ...state.age_of_receipt,
            errorMsg:
              value < min || value > max || value
                ? maxMinErrorMsg.age
                : 'ErrorMsg.PleaseInputTheAge',
          },
        }));
      }

      if (Array.isArray(curData)) {
        if ((index || index === 0) && type !== 'remove') {
          if (index >= curData.length) {
            curData = [...curData, defaultConfigs[currentCardName]];
          }
          if (name === 'other_investment_style') {
            curData[index || 0][name] = value + 1;
          } else {
            if (name === 'is_after_retirement' && !value) {
              delete curData[index || 0].age_of_receipt;
              handleMaxMinErrorState(`age_of_receipt${index}`, false);
              handleErrorState(`age_of_receipt${index}`, false);
            }
            curData[index || 0][name] = value;
          }
        }
        if (type === 'remove') {
          curData.splice(index || 0, 1);
        }
        setCurrentData((data: Record<string, any>) => ({
          ...data,
          [fieldMap[currentCardName]]: curData,
        }));
      }
    }
    if (needStepFields.includes(name)) {
      if (name === 'monthly_income') {
        const savings = currentData.monthly_mpf_savings || 0;
        const max = maxTotalValue(value > 300000 ? 300000 : value, savings);
        let mpfSavingMax = value;
        if (value >= 7100) {
          if (0.05 * value >= 1500) {
            mpfSavingMax = value - 1500;
          } else {
            mpfSavingMax = 0.95 * value;
          }
        }
        const { min: rMin, max: rMax } = getRetirementMonthlyMinMax(value);

        const monthlyCashSaving = currentData.monthly_cash_savings || 0;
        let suffix = currencyFormat(0);
        if (monthlyCashSaving < max) {
          suffix = currencyFormat(value * monthlyCashSaving);
          updateMonthlyCashIncome(max, suffix, monthlyCashSaving * 100);
        } else {
          suffix = currencyFormat((value * max) / 100);
          updateMonthlyCashIncome(max, suffix, max);
        }
        setMinMaxStep((state) => ({
          ...state,
          monthly_mpf_savings: {
            ...state.monthly_mpf_savings,
            max: mpfSavingMax,
          },
          retirement_monthly_income: {
            ...state.retirement_monthly_income,
            min: rMin,
            max: rMax,
          },
        }));
        setCurrentData((data: Record<string, any>) => ({
          ...data,
          monthly_cash_savings: value ? monthlyCashSaving : 0,
          retirement_monthly_income: rMin,
          retirement_monthly_income_style: 'Modest',
        }));
        if (currentData.monthly_mpf_savings > mpfSavingMax) {
          setCurrentData((data: Record<string, any>) => ({
            ...data,
            monthly_mpf_savings: mpfSavingMax,
          }));
        }
      }

      if (name === 'monthly_mpf_savings') {
        const savings = value || 0;
        const max = maxTotalValue(
          currentData.monthly_income > 300000
            ? 300000
            : currentData.monthly_income,
          savings
        );
        const cashSavingMax = Math.round(max < 0 ? 0 : max);
        const monthlyCashSaving = currentData.monthly_cash_savings || 0;
        const curMonthlyCashSaving = monthlyCashSaving * 100;
        let suffix = currencyFormat(0);
        if (curMonthlyCashSaving < max) {
          suffix = currencyFormat(
            monthlyCashSaving * currentData.monthly_income
          );
          updateMonthlyCashIncome(
            cashSavingMax,
            suffix,
            monthlyCashSaving * 100
          );
        } else {
          suffix = currencyFormat(max * currentData.monthly_income);
          updateMonthlyCashIncome(max, suffix, max);
        }
        if (curMonthlyCashSaving > cashSavingMax) {
          setCurrentData((data: Record<string, any>) => ({
            ...data,
            monthly_cash_savings: currentData.monthly_income
              ? cashSavingMax / 100
              : '',
          }));
        }
      }

      if (name === 'retirement_monthly_income') {
        let retirementStyle = currentData.retirement_monthly_income_style;
        retirementStyle = getRetirementMonthlyStyle(
          currentData.monthly_income,
          value
        );
        setCurrentData((data: Record<string, any>) => ({
          ...data,
          [name]: value,
          retirement_monthly_income_style: retirementStyle || 'Modest',
        }));
      }
      setCurrentData((data: Record<string, any>) => ({
        ...data,
        [name]: value,
      }));
    } else if (!isAddCard) {
      if (needCurrentHandleErrorFields.includes(name)) {
        handleErrorState(name, false);
      }
      if (name === 'age') {
        const { min, max } = minMaxStep[name];
        const retirementAgeMin =
          value > max ? max + 1 : value > 39 ? +(value + 1) : 40;
        const retirementAgeError =
          currentData.retirement_age < retirementAgeMin ||
          currentData.retirement_age > 69;
        const ageOfReceiptMin = value > 39 ? +(value + 2) : 41;
        setMinMaxStep((state) => ({
          ...state,
          age: {
            ...state.age,
            errorMsg:
              value < min || value > max || value ? maxMinErrorMsg[name] : '',
          },
          retirement_age: {
            ...state.retirement_age,
            min: retirementAgeMin,
            errorMsg:
              value === 69
                ? 'ErrorMsg.PleaseInputYourRetirementAgeAt'
                : retirementAgeError
                ? maxMinErrorMsg.retirement_age
                : '',
          },
          age_of_receipt: {
            ...state.age_of_receipt,
            min: value > 39 ? +(value + 2) : 41,
          },
        }));
        handleErrorState('retirement_age', retirementAgeError);
        handleMaxMinErrorState('retirement_age', retirementAgeError);
        handleAgeOfReceiptError(ageOfReceiptMin);
      }
      if (name === 'retirement_age') {
        const { min, max } = minMaxStep[name];
        const ageOfReceiptMin = value >= 71 ? 71 : +(value + 1);
        setMinMaxStep((state) => ({
          ...state,
          retirement_age: {
            ...state.retirement_age,
            errorMsg:
              currentData.age === 69
                ? 'ErrorMsg.PleaseInputYourRetirementAgeAt'
                : value < min || value > max || value
                ? maxMinErrorMsg[name]
                : '',
          },
          age_of_receipt: { ...state.age_of_receipt, min: ageOfReceiptMin },
        }));
        handleAgeOfReceiptError(ageOfReceiptMin);
      }
      setCurrentData((data: Record<string, any>) => ({
        ...data,
        [name]: sliderFieldsName.includes(name)
          ? sliderFieldsMap[name][value]
          : value,
      }));
      if (name === 'retirement_monthly_income_style') {
        const curRetirementMI = getRetirementMonthlyIncome(
          currentData.monthly_income,
          value
        );
        setCurrentData((data: Record<string, any>) => ({
          ...data,
          retirement_monthly_income: curRetirementMI,
        }));
      }
      if (name === 'monthly_cash_savings') {
        const curAdditionalSaving = value / 100;
        setMinMaxStep((state) => ({
          ...state,
          monthly_cash_savings: {
            ...state.monthly_cash_savings,
            suffix: currencyFormat(
              curAdditionalSaving * currentData.monthly_income
            ),
            tooltipValue: percentageFormat(curAdditionalSaving),
          },
        }));
        setCurrentData((data: Record<string, any>) => ({
          ...data,
          [name]: value / 100,
        }));
      }
    }
  };

  const beforeEdit = (name: string) => {
    let canIEdit = true;
    if (role === 'advisor' && !advisorCanEdit.current) {
      canIEdit = false;
      activeName.current = name;
      switchVModal(
        sendedVCode.current ? 'verificationCode' : 'requestPermission'
      );
    }
    return canIEdit;
  };

  const setCurrentEdit = (name: string, value: boolean) => {
    if (!beforeEdit(name) && value) return;
    if (addCards.includes(name) && !currentData[fieldMap[name]]) {
      setCurrentData((data: Record<string, any>) => ({
        ...data,
        [fieldMap[name]]: [defaultConfigs[name]],
      }));
      updateValue(0, 'amount', name, 0, 'add');
    }
    setIsEdit((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const portfolioTotalValue = useMemo(() => {
    const data = (currentData?.other_investment || []).map(
      (item: Record<string, any>) => item.amount
    );
    return data.length
      ? data.reduce((prev: any, curr: any) => (prev || 0) + (curr || 0))
      : currentData.other_investment_rc ?? 0;
  }, [currentData]);

  const fundsTotalValue = useMemo(() => {
    const data = (currentData?.future_payments || []).map(
      (item: Record<string, any>) => item.amount
    );
    return data.length
      ? data.reduce((prev: any, curr: any) => (prev || 0) + (curr || 0))
      : 0;
  }, [currentData]);

  useEffect(() => {
    if (currentData.other_investment) changeConfig('otherInvestments');
    if (currentData.future_payments) changeConfig('futurePayments');
  }, [currentData, changeConfig]);

  const onAdd = (name: string) => {
    if (!beforeEdit(name) && !isEdit[name]) return;
    setCurrentData((data: Record<string, any>) => ({
      ...data,
      [fieldMap[name]]: [...currentData[fieldMap[name]], defaultConfigs[name]],
    }));
    if (currentData[fieldMap[name]].length === 0) {
      updateValue(0, 'amount', name, 0, 'add');
    }
    changeConfig(name);
    setCurrentEdit(name, true);
  };

  const onRemove = (name: string, cardName?: string, index?: number) => {
    const currentCardName = cardName || 'otherInvestments';
    const currentRemoveConfig = currentConfigs[currentCardName];
    const curConfig = currentRemoveConfig.filter(
      (item: IFormField) => item.index !== (index || 0)
    );
    const filterConfig = curConfig.map(
      (item: IFormField, itemIndex: number) => ({
        ...item,
        index: (itemIndex % 2 === 0 ? itemIndex : itemIndex - 1) / 2,
      })
    );
    if (currentCardName === 'futurePayments') {
      delete errorState[`payment_description${filterConfig.length / 2}`];
      delete errorState[`age_of_receipt${filterConfig.length / 2}`];
      delete maxMinErrorState[`age_of_receipt${filterConfig.length / 2}`];
    }
    updateValue('', '', cardName, index, 'remove');
    setCurrentConfigs((config) => ({
      ...config,
      [currentCardName]: filterConfig,
    }));
  };

  const closeEditState = useCallback(
    () => setIsEdit((prev) => filterObj(prev, false)),
    []
  );
  const cancel = useCallback(() => {
    closeEditState();
    setCurrentData({
      ...initialCurrentData,
      ...retirementProfile,
    });
    const monthlyIncome = retirementProfile?.monthly_income;
    const savings = retirementProfile?.monthly_mpf_savings || 0;
    const curTooltipValue = retirementProfile?.monthly_cash_savings;
    const max = maxTotalValue(monthlyIncome, savings);
    setMinMaxStep((prevState) => ({
      ...prevState,
      monthly_cash_savings: {
        ...prevState.monthly_cash_savings,
        max,
        suffix: currencyFormat(monthlyIncome * curTooltipValue),
        tooltipValue: percentageFormat(curTooltipValue),
      },
    }));
  }, [closeEditState, retirementProfile]);

  const saveChange = async () => {
    const {
      age,
      gender,
      marital_status,
      smoking_status,
      monthly_income,
      retirement_age,
    } = currentData;
    let fieldsValue = {
      age,
      gender,
      marital_status,
      smoking_status,
      monthly_income,
      retirement_age,
    };

    const { other_investment, future_payments } = currentData;
    if (other_investment) {
      other_investment.forEach(
        (item: { investment_description: string }, index: number) => {
          handleErrorState(
            `investment_description${index}`,
            item.investment_description === ''
          );
          fieldsValue = {
            ...fieldsValue,
            [`investment_description${index}`]: item.investment_description,
          };
        }
      );
    }
    if (future_payments) {
      future_payments.forEach(
        (
          item: {
            payment_description: string;
            is_after_retirement: boolean;
            age_of_receipt: number | '';
          },
          index: number
        ) => {
          const ageOfReceiptError =
            item.is_after_retirement &&
            (!item.age_of_receipt ||
              (!!item.age_of_receipt &&
                item.age_of_receipt < minMaxStep.age_of_receipt.min));
          handleErrorState(
            `payment_description${index}`,
            !item.payment_description
          );
          handleMaxMinErrorState(`age_of_receipt${index}`, ageOfReceiptError);
          fieldsValue = {
            ...fieldsValue,
            [`payment_description${index}`]: item.payment_description,
            [`age_of_receipt${index}`]: item.is_after_retirement
              ? item.age_of_receipt
              : 'haveValue',
          };
          setMinMaxStep((state) => ({
            ...state,
            age_of_receipt: {
              ...state.age_of_receipt,
              errorMsg: item.age_of_receipt
                ? maxMinErrorMsg.age
                : 'ErrorMsg.PleaseInputTheAge',
            },
          }));
        }
      );
    }

    const emptyField = isEmptyFields(fieldsValue);
    const isErrorField = isEmptyField(maxMinErrorState, 'boolean');

    setSaveChanged(true);
    setTimeout(() => {
      setSaveChanged(false);
    }, 1000);
    if (!age) {
      handleMaxMinErrorState('age', true);
      handleErrorState('age', true);
    }
    if (!retirement_age) {
      handleMaxMinErrorState('retirement_age', true);
      handleErrorState('retirement_age', true);
    }
    if (emptyField.length > 0) {
      setErrorState((state) => {
        const curErrorState = { ...state };
        emptyField.forEach((name: string) => {
          curErrorState[name] = true;
        });
        return curErrorState;
      });
      setMinMaxStep((state) => ({
        ...state,
        age: {
          ...state.age,
          errorMsg: maxMinErrorState.age ? state.age.errorMsg : '',
        },
        retirement_age: {
          ...state.retirement_age,
          errorMsg: maxMinErrorState.retirement_age
            ? state.retirement_age.errorMsg
            : '',
        },
      }));
    } else if (!isErrorField) {
      try {
        if (retirementProfile?.gender) {
          await updateRetirementProfileAPI({
            id: clientInfo?.id ?? '',
            role,
            language,
            ...currentData,
          });
        } else {
          await createRetirementProfile({
            client_id: clientInfo?.id,
            language,
            ...currentData,
          });
        }
        closeEditState();
        dispatch(updateRetirementProfile(currentData));
        dispatch(updateUserInfo({ age: currentData.age }));
        setShowError?.(false);
      } catch (error: any) {
        if (error === 10021) {
          try {
            await updateRetirementProfileAPI({
              id: clientInfo?.id ?? '',
              role,
              language,
              ...currentData,
            });
            closeEditState();
            dispatch(updateRetirementProfile(currentData));
            dispatch(updateUserInfo({ age: currentData.age }));
            setShowError?.(false);
          } catch (e) {
            setErrorCode?.(error);
            setShowError?.(true);
          }
        }
        setErrorCode?.(error);
        setShowError?.(true);
      }
    }
  };

  // convert expired time to undefined for easier logic flow
  const adjustedEditSessionExpiry = useMemo(
    () =>
      dayjs(advisorEdit[clientId]).isAfter(dayjs())
        ? advisorEdit[clientId]
        : undefined,
    [advisorEdit, clientId]
  );

  const startExpiredTimeResendInterval = useCallback(() => {
    expiredTimeResendInterval = setInterval(() => {
      const expiredSecond = dayjs(adjustedEditSessionExpiry).diff(
        dayjs(),
        'second'
      );
      if (expiredSecond <= 0) {
        cancel();
        clearInterval(expiredTimeResendInterval);
        setExpiredResendCountdown(0);
        switchVModal('sessionExpired');
        advisorCanEdit.current = false;
      } else {
        if (expiredSecond <= 60 && !isShowLastMinute.current) {
          switchVModal('lastMinute');
          isShowLastMinute.current = true;
        } else if (
          expiredSecond <= 300 &&
          !isShowExpireInMinutes.current &&
          expiredSecond > 60
        ) {
          switchVModal('expireInMinutes');
          isShowExpireInMinutes.current = true;
        }
        setExpiredResendCountdown(expiredSecond);
      }
    }, 500);
  }, [adjustedEditSessionExpiry, cancel]);

  useEffect(() => {
    // Clear edit expiry no matter what
    clearInterval(expiredTimeResendInterval);
    // Check if incoming edit expiry is valid
    if (adjustedEditSessionExpiry) {
      advisorCanEdit.current = true;
      startExpiredTimeResendInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedEditSessionExpiry]);

  const startResendInterval = () => {
    const seconds = 60;
    const currentTime = dayjs();
    const exceedTime = currentTime.add(seconds, 's');
    setResendCountdown(seconds);
    resendInterval = setInterval(() => {
      const diff = exceedTime.diff(dayjs(), 'second');
      if (diff <= seconds && diff > 0) {
        setResendCountdown(diff);
      } else {
        setResendCountdown(0);
        clearInterval(resendInterval);
      }
    }, 500);
  };

  const sendVCodeToClient = async () => {
    sendedVCode.current = true;
    try {
      await editRequest({
        clientId,
        role,
      });
      startResendInterval();
      if (!controlVModal.verificationCode) {
        switchVModal('verificationCode');
      }
    } catch (error) {
      // TODO: Show error
      console.log(error);
    }
  };

  const verifyCode = async (code: string) => {
    try {
      await editRequestVerify({
        clientId,
        otp: code,
      });
      await dispatch(editSessionStatus(clientId));
      switchVModal('verificationCode');
      setCurrentEdit(activeName.current, true);
    } catch (error) {
      // TODO: Show error
      console.log(error);
      setCodeError(true);
    }
  };

  useEffect(() => {
    const paramsObject = new URLSearchParams(location.search);
    if (role === 'advisor') {
      const member_id = paramsObject.get('member_id');
      setClientId(member_id ?? '');
      if (!member_id && paramsObject.get('create_profile')) {
        advisorCanEdit.current = true;
      }
    } else if (role === 'member') {
      const edit = paramsObject.get('edit');
      const locale = paramsObject.get('locale') || language || 'en';
      if (edit) {
        setIsEdit(() => filterObj(createIsEditObj(), true));
        history.replace({
          pathname: location.pathname,
          search: `locale=${locale}`,
        });
      }
    }
  }, [role, location, history, language]);

  const closeAgePopup = () => {
    setCurrentData((prevState) => ({
      ...prevState,
      age: '',
    }));
    setMinMaxStep((prevState) => ({
      ...prevState,
      age: {
        ...prevState,
        errorMsg: 'ErrorMsg.PleaseInputTheAge',
      },
    }));
    setModalVisible('age', false);
    handleMaxMinErrorState('age', true);
  };

  return (
    <DetailsContentWrapper>
      <DetailsContent style={{ paddingBottom: pxToRem(isEmpty ? 14 : 90) }}>
        <DetailsNote>
          <FormattedMessage id="Product.ItemsMarkedWith" />
        </DetailsNote>
        {currentConfig.map((config, index) =>
          isEdit[config.name] ? (
            <RetirementDetailsItems
              key={`${config.name} ${index + 1}`}
              type={config.type}
              setIsEdit={() => setCurrentEdit(config.name, false)}
              formData={{
                ...currentData,
                monthly_cash_savings: currentData.monthly_cash_savings * 100,
              }}
              itemConfig={config.config}
              titleConfig={config.titleConfig}
              subTitleConfig={config.subTitleConfig}
              updateValue={updateValue}
              prefix="Product"
              onAdd={onAdd}
              onRemove={onRemove}
              addMsg={config.addMsg}
              errorState={allErrorState}
              minMaxStep={minMaxStep}
              setShowHelp={setModalVisible}
              onBlur={onBlur}
              portfolioTotal={portfolioTotalValue}
              fundsTotal={fundsTotalValue}
              handleErrorState={handleErrorState}
              handleMaxMinErrorState={handleMaxMinErrorState}
              activeTopError={saveChanged ? activeTopError : ''}
            />
          ) : (
            <ShowValue
              key={`${config.name} ${index + 1}`}
              type={config.type}
              setIsEdit={() => setCurrentEdit(config.name, true)}
              formData={currentData}
              itemConfig={config.config}
              titleConfig={config.titleConfig}
              subTitleConfig={config.subTitleConfig}
              prefix="Product"
              onAdd={onAdd}
              addMsg={config.addMsg}
              setShowHelp={setModalVisible}
              portfolioTotal={portfolioTotalValue}
              fundsTotal={fundsTotalValue}
            />
          )
        )}
        {/* { */}
        {/*  role !== 'member' && <PurchasedSunLifeProducts setModalVisible={setModalVisible} /> */}
        {/* } */}
      </DetailsContent>
      {!isEmpty && (
        <DetailsButtonsFooter>
          <ButtonsFooter
            config={[
              {
                width: 188,
                type: 'secondary',
                message: 'Common.Cancel',
                onClick: cancel,
              },
              {
                width: 188,
                type: 'primary',
                message: 'Common.SaveChanges',
                onClick: saveChange,
              },
            ]}
          />
        </DetailsButtonsFooter>
      )}
      <ExpireInMinutes
        visible={controlVModal.expireInMinutes}
        onClose={() => switchVModal('expireInMinutes')}
      />
      <LastMinute
        visible={controlVModal.lastMinute}
        onClose={() => switchVModal('lastMinute')}
        seconds={expiredCountdown}
      />
      <RequestPermission
        visible={controlVModal.requestPermission}
        onClose={() => switchVModal('requestPermission')}
        onSend={sendVCodeToClient}
      />
      <SessionExpired
        visible={controlVModal.sessionExpired}
        onClose={() => switchVModal('sessionExpired')}
        onResendRequest={sendVCodeToClient}
      />
      <VerificationCode
        visible={controlVModal.verificationCode}
        expireHour={4}
        error={codeError}
        seconds={resendCountdown}
        onResend={sendVCodeToClient}
        onConfirm={verifyCode}
        onChange={() => setCodeError(false)}
        onClose={() => switchVModal('verificationCode')}
      />
      <RetirementGapModal visible={modalVisible.age} onClose={closeAgePopup} />
    </DetailsContentWrapper>
  );
};

export default Details;
