import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Input as AntInput } from 'antd';
import {
  enlargeFontsize,
  isEmptyField,
  pxToRem,
  toLogin,
} from '@rjp/common/utils';
import {
  Label,
  Input,
  Button,
  SideCard3,
  InfoModal,
  SideCard,
  CheckBox,
  Modal,
  ApiErrorTip,
} from '@rjp/common/component';
import { ReactComponent as ErrorIcon } from '@rjp/common/assets/images/error-icon.svg';
import { ReactComponent as CheckSolid } from '@rjp/common/assets/images/check-solid.svg';
import { reg } from '@rjp/common/config';
import {
  createMember,
  ICreateMember,
  memberVerificationRequest,
} from '../../api';
import CreateAccountHeader from './CreateAccountHeader';
import { RootState } from '../../app';
import {
  fieldConfig,
  passwordRule1,
  passwordRule2,
  createdAccountContent,
  verificationModalConfig,
  activatedModalConfig,
  expiredModalConfig,
  passwordReg,
} from './config';
import { RJP_LINK, RJP_VIEW, Tracking, trackingFunc } from '@/utils/tracking';

const { Group } = AntInput;

const Wrapper = styled.div`
  font-size: ${pxToRem(40)};
  white-space: pre-wrap;
`;

const Content = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  width: ${pxToRem(618)};
  margin-left: ${pxToRem(30)};
`;

const ContentTitle = styled.div`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
  line-height: ${pxToRem(34)};
  margin: ${pxToRem(7)} 0;
`;

const FieldWrapper = styled.div<{ marginBottom?: number }>`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
  margin-bottom: ${(props) => pxToRem(props.marginBottom || 10)};
  display: flex;
  justify-content: space-between;
`;

const SingleFieldWrapper = styled.div`
  width: ${pxToRem(300)};
`;

const FieldTitle = styled(Label)`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  height: ${pxToRem(21)};
  line-height: ${pxToRem(21)};
`;

const InputGroup = styled(Group)`
  &.ant-input-group {
    display: flex;
    justify-content: space-between;
  }
`;

const PasswordNote = styled.div`
  width: ${pxToRem(618)};
  height: ${pxToRem(73)};
  line-height: ${pxToRem(18)};
  margin-top: ${pxToRem(6)};
  padding: ${pxToRem(12)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
  background: ${(props) => props.theme.color.extra_cool_blue_light_30};
  color: ${(props) => props.theme.color.coolblue};
  display: flex;

  svg {
    width: ${pxToRem(11)};
    path {
      fill: ${(props) => props.theme.color.sunlife_blue_light};
    }
  }
`;

const PasswordNoteLeft = styled.div`
  width: ${pxToRem(258)};
`;

const PICS = styled(Label)`
  margin-top: ${pxToRem(18)};
  margin-bottom: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
`;

const PICSLink = styled.span`
  color: ${(props) => props.theme.color.coolblue};
  text-decoration: underline;
  cursor: pointer;
}
`;

const PICSContent = styled.div`
  margin-top: ${pxToRem(7)};
  font-weight: normal;
  display: flex;
  color: ${(props) => props.theme.color.coolblue};
`;

const PICSCheckBoxNote = styled.div`
  width: ${pxToRem(540)};
  margin-left: ${pxToRem(5)};
  font-weight: normal;
`;

const CurRCSideCard = styled(SideCard3)`
  position: absolute;
  right: ${pxToRem(13)};
  top: ${pxToRem(20)};
`;

const ResendEmailContent = styled.div`
  padding-top: ${pxToRem(56)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${pxToRem(31)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ResendEmailTitle = styled(Label)`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${pxToRem(31)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ResendEmailOther = styled.div<{ marginTop: number }>`
  margin-top: ${(props) => pxToRem(props.marginTop)};
`;

const ResendEmailButton = styled((props) => <Button {...props} />)`
  &.ant-btn {
    margin-top: ${pxToRem(15)};
    font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  }
`;

const CurGoRCSideCard = styled(SideCard)`
  position: absolute;
  right: ${pxToRem(10)};
  top: ${pxToRem(-130)};
`;

const PICSModal = styled(Modal)``;

const PICSModalTitle = styled.div`
  text-align: center;
  height: ${pxToRem(50)};
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(20)};
  color: #004c6c;
  font-weight: bold;
  margin-top: ${pxToRem(-10)};
`;

const PICSModalContent = styled.div`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  white-space: pre-wrap;
  color: #424242;
  max-height: ${pxToRem(460)};
  overflow: auto;
`;

const ErrorInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding: 0 ${pxToRem(21)};
    padding-top: ${pxToRem(46)};
  }
  .ant-modal-footer {
    padding-top: 0;
    padding-bottom: ${pxToRem(40)};
  }
`;

const Check = styled.div`
  width: ${pxToRem(16)};
  padding-left: ${pxToRem(2)};
  display: inline-block;
`;

const AreaCode = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(16)};
  height: ${pxToRem(44)};
  line-height: ${pxToRem(44)};
  color: ${(props) => props.theme.color.grey_dark};
`;

const PhoneNumberInputWrap = styled.div`
  position: relative;
  width: ${pxToRem(244)};
  margin-top: ${pxToRem(21)};

  .mobile-input {
    height: ${pxToRem(44)};
  }

  .error-msg {
    width: ${pxToRem(300)};
    position: relative;
    left: -${pxToRem(56)};
    ::before {
      left: ${pxToRem(72)};
    }
  }
`;

const CreateAccount: FC<RouteComponentProps> = ({ history, location }) => {
  const memberId = useRef<string>('');
  const themeContext = useContext(ThemeContext);
  const { retirementGapSummary, RCPasteId } = useSelector(
    (state: RootState) => state.RCData,
    shallowEqual
  );
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const [formValue, setFormValue] = useState<Record<string, any>>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile: '',
    area_code: '852',
    is_opt_in_direct_marketing: false,
  });
  const [confirmEmail, setConfirmEmail] = useState('');
  const [hasError, setHasError] = useState<Record<string, boolean>>({
    first_name: false,
    last_name: false,
    email: false,
    confirmEmail: false,
    password: false,
    mobile: false,
  });
  const [confirmEmailErrorMsg, setConfirmEmailErrorMsg] = useState(
    'InvalidEmailAddressFormat'
  );
  const [isCreated, setIsCreated] = useState(false);
  const [verificationVisible, setVerificationVisible] = useState(false);
  const [visibleExpired, setVisibleExpired] = useState(false);
  const [activatedVisible, setActivatedVisible] = useState(false);
  const [showPICSModal, setShowPICSModal] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  const [countdown, setCountdown] = useState(60);
  const [sendLabel, setSendLabel] = useState('ResendEmailCountdown');

  let timer:NodeJS.Timeout;

  const createAccountState = useMemo(() => {
    const hasErrorValue = isEmptyField(hasError, 'boolean');
    const formValues = isEmptyField(formValue);
    return !hasErrorValue && !formValues && !!confirmEmail;
  }, [hasError, formValue, confirmEmail]);

  const errorHelper = (error: string) => {
    setShowError(true);
    setErrorCode(error.toString());
  };

  const handleSetError = (name: string, value: boolean) => {
    setHasError((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const goRetirement = () => {
    const retirementLanguage: { [key: string]: string } = {
      en: 'en',
      'zh-hant': 'zh-tw',
    };
    window.open(
      `${process.env.REACT_APP_RETIREMENT_CALCULATOR_URL}/${retirementLanguage[language]}`
    );
    setVisibleExpired(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      setIsCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentDot = (name: string) => {
    const result = passwordReg[name].test(formValue.password);
    return result ? (
      <Check>
        <CheckSolid />
      </Check>
    ) : (
      ' •'
    );
  };

  const handleChange = (name: string, value: any) => {
    if (name === 'confirmEmail') {
      setConfirmEmail(value);
    } else {
      setFormValue((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (name === 'mobile' && !!value && !formValue.area_code) {
      setFormValue((values) => ({
        ...values,
        area_code: '852',
      }));
    }
    if (name === 'area_code') {
      if (value === '852') {
        handleSetError(
          'mobile',
          !reg.hkTelephone.test(formValue.mobile) && formValue.mobile !== ''
        );
      } else {
        handleSetError(
          'mobile',
          !(
            reg.mobile.test(formValue.mobile) ||
            reg.telephone.test(formValue.mobile)
          ) && formValue.mobile !== ''
        );
      }
    }
  };

  const handleOnBlur = (name: string, value: any) => {
    if (name === 'confirmEmail') {
      handleSetError(
        name,
        value !== '' && (value !== formValue.email || !reg.email.test(value))
      );
      if (!reg.email.test(value)) {
        setConfirmEmailErrorMsg('InvalidEmailAddressFormat');
      } else if (value !== formValue.email && value !== '') {
        setConfirmEmailErrorMsg('EmailDoesNotMatch');
      }
    } else if (name === 'email' && confirmEmail) {
      handleSetError(
        'confirmEmail',
        !(value === confirmEmail && reg.email.test(confirmEmail))
      );
    }
  };

  const handleCreateAccount = useCallback(async () => {
    try {
      const responseData = await createMember({
        ...formValue,
        is_created_by_agent: false,
        language,
      } as ICreateMember);
      memberId.current = responseData.member.id;
      setIsCreated(true);
      setShowError(false);
      memberVerificationRequest({
        member_id: responseData.member.id,
        language,
      });
    } catch (error: any) {
      errorHelper(error);
    }
  }, [formValue, language]);

  const handleResendEmail = async () => {
    try {
      await memberVerificationRequest({
        member_id: memberId.current,
        language,
      });
      setVerificationVisible(true);
      setShowError(false);
      timer = setInterval(() => setCountdown((t) => t - 1), 1000);
      setSendLabel('ResendEmailCountdown');
    } catch (error:any) {
      errorHelper(error);
      if (error === 10007) {
        history.push('/member/accountactivated');
      }
    }
  };

  const handleCloseVerificationModal = () => {
    setVerificationVisible(false);
  };

  const handleCloseActivatedModal = () => {
    setActivatedVisible(false);
  };

  const goLogin = toLogin('member', history);

  const resendEmailState = useMemo(() => {
    if (countdown === 0 && timer && isCreated) {
      window.clearInterval(timer);
      setSendLabel('ResendEmail');
      setCountdown(60);
    }
    return (
      countdown !== 0 &&
      timer &&
      isCreated &&
      sendLabel === 'ResendEmailCountdown'
    );
  }, [isCreated, countdown, sendLabel]);

  useEffect(() => {
    setCountdown(60);
    if (isCreated) {
      timer = setInterval(() => setCountdown((t) => t - 1), 1000);
      setSendLabel('ResendEmailCountdown');
    }
  }, [isCreated]);

  useEffect(() => {
    trackingFunc(RJP_VIEW, 'other', 'main screen');
  }, []);

  const noSaveResult = useMemo(
    () => !(RCPasteId && retirementGapSummary.retirement_age),
    [RCPasteId, retirementGapSummary.retirement_age]
  );

  return (
    <Wrapper>
      <CreateAccountHeader
        isCreated={isCreated}
        history={history}
        noSaveResult={noSaveResult}
      />
      <Content>
        <ContentWrapper>
          {!isCreated ? (
            <>
              <ContentTitle>
                <FormattedMessage id="Login.CreateAccount.AllFieldsAreMandatory" />
              </ContentTitle>
              {fieldConfig.map((config, index) => (
                <FieldWrapper
                  marginBottom={index === 1 ? 20 : 10}
                  key={`Field${index}`}
                >
                  {config.map(({ type, name, title, subTitle, errorMsg }) => (
                    <SingleFieldWrapper key={title}>
                      {type === 'phoneNumber' ? (
                        <InputGroup>
                          <div>
                            <FieldTitle>
                              <FormattedMessage
                                id={`Login.CreateAccount.${title}`}
                              />
                            </FieldTitle>
                            <AreaCode>+{formValue.area_code}</AreaCode>
                          </div>
                          <PhoneNumberInputWrap>
                            <Input
                              width={244}
                              name={name}
                              className="mobile-input"
                              type={type}
                              hasError={hasError[name]}
                              onChange={handleChange}
                              setError={handleSetError}
                              onBlur={(e: any) =>
                                handleOnBlur(name, e?.target?.value)
                              }
                              color={themeContext.color.grey_medium}
                              errorMsg={`ErrorMsg.${errorMsg}`}
                              countryCode={formValue.area_code}
                            />
                          </PhoneNumberInputWrap>
                        </InputGroup>
                      ) : (
                        <Input
                          name={name}
                          type={type}
                          title={`Login.CreateAccount.${title}`}
                          subTitle={
                            subTitle ? `Login.CreateAccount.${subTitle}` : ''
                          }
                          hasError={hasError[name]}
                          maxLength={type === 'name' ? 20 : 50}
                          onChange={handleChange}
                          setError={handleSetError}
                          onBlur={(e: any) =>
                            handleOnBlur(name, e?.target?.value)
                          }
                          color={themeContext.color.grey_medium}
                          errorMsg={`ErrorMsg.${
                            name === 'confirmEmail'
                              ? confirmEmailErrorMsg
                              : errorMsg
                          }`}
                        />
                      )}
                    </SingleFieldWrapper>
                  ))}
                </FieldWrapper>
              ))}
              <PasswordNote>
                <PasswordNoteLeft>
                  <Label>
                    <FormattedMessage id="Login.CreateAccount.PasswordMustContain" />
                  </Label>
                  {passwordRule1.map((message) => (
                    <div key={message}>
                      <FormattedMessage
                        id={`Login.CreateAccount.${message}`}
                        values={{ checkDot: currentDot(message) }}
                      />
                    </div>
                  ))}
                </PasswordNoteLeft>
                <div>
                  {passwordRule2.map((message) => (
                    <div key={message}>
                      <FormattedMessage
                        id={`Login.CreateAccount.${message}`}
                        values={{ checkDot: currentDot(message) }}
                      />
                    </div>
                  ))}
                </div>
              </PasswordNote>
              <PICS>
                <FormattedMessage
                  id="Login.CreateAccount.IHaveReadAndUnderstood"
                  values={{
                    value: (
                      <PICSLink onClick={() => setShowPICSModal(true)}>
                        <FormattedMessage id="Login.CreateAccount.PICS" />
                      </PICSLink>
                    ),
                  }}
                />
                <PICSContent>
                  <CheckBox
                    name="is_opt_in_direct_marketing"
                    type="primary-blue"
                    width={16}
                    onChange={handleChange}
                  >
                    <PICSCheckBoxNote>
                      <FormattedMessage id="Login.CreateAccount.ByClickingTheBox" />
                    </PICSCheckBoxNote>
                  </CheckBox>
                </PICSContent>
              </PICS>
              <ApiErrorTip width={617} showError={showError} id={errorCode} />
              <Button
                type={createAccountState ? 'primary-yellow' : 'disabled'}
                width={180}
                style={{ marginTop: pxToRem(22) }}
                onClick={() => {handleCreateAccount(); trackingFunc(RJP_LINK, 'other', 'create account')}}
              >
                <FormattedMessage id="Login.CreateAccount.CreateAccount" />
              </Button>
            </>
          ) : (
            <ResendEmailContent>
              <ResendEmailTitle>
                <FormattedMessage id="Login.CreateAccount.WeHaveJustSentYou" />
              </ResendEmailTitle>
              {createdAccountContent.map(({ marginTop, text }) => (
                <ResendEmailOther marginTop={marginTop} key={text}>
                  <FormattedMessage id={`Login.CreateAccount.${text}`} />
                </ResendEmailOther>
              ))}
              <ResendEmailButton
                type={resendEmailState ? 'disabled' : 'primary-yellow'}
                width={resendEmailState ? 200 : 180}
                onClick={handleResendEmail}
              >
                <FormattedMessage
                  id={`Login.CreateAccount.${sendLabel}`}
                  values={{ countdown }}
                />
              </ResendEmailButton>
            </ResendEmailContent>
          )}
        </ContentWrapper>
        {!noSaveResult ? <CurRCSideCard /> : <CurGoRCSideCard />}
      </Content>
      <PICSModal
        visible={showPICSModal}
        width={720}
        onClose={() => setShowPICSModal(false)}
      >
        <PICSModalTitle>
          <FormattedMessage id="Login.CreateAccount.PICSTitle" />
        </PICSModalTitle>
        <PICSModalContent>
          <FormattedMessage id="Login.CreateAccount.PICSContent" />
        </PICSModalContent>
      </PICSModal>
      <InfoModal
        visible={verificationVisible}
        onClose={() => setVerificationVisible(false)}
        footerOnClose={handleCloseVerificationModal}
        {...verificationModalConfig}
      />
      <InfoModal
        visible={activatedVisible}
        onClose={handleCloseActivatedModal}
        footerOnClose={() => {goLogin();}}
        {...activatedModalConfig}
      />
      <ErrorInfoModal
        visible={visibleExpired}
        onClose={() => setVisibleExpired(false)}
        footerOnClose={goRetirement}
        {...expiredModalConfig(<ErrorIcon width={pxToRem(80)} />)}
      />
    </Wrapper>
  );
};

export default CreateAccount;
